/*
  Authors : initappz (Rahul Jograna)
  Website : https://initappz.com/
  App Name : ionic 5 groceryee app
  Created : 10-Sep-2020
  This App Template Source code is licensed as per the
  terms found in the Website https://initappz.com/license
  Copyright and Good Faith Purchasers © 2020-present initappz.
*/
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layouts/admin/admin.component';
import { AuthComponent } from './layouts/auth/auth.component';
import { AuthGuard } from './guard/auth.guard';
import { LeaveGuard } from './leaved/leaved.guard';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'stats',
        loadChildren: () => import('./pages/stats/stats.module').then(m => m.StatsModule),
        canActivate: [AuthGuard]
      },

      {
        path: 'manage-orders',
        loadChildren: () => import('./pages/manage-orders/manage-orders.module').then(m => m.ManageOrdersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'contacts',
        loadChildren: () => import('./pages/contacts/contacts.module').then(m => m.ContactsModule),
        canDeactivate: [LeaveGuard],
        canActivate: [AuthGuard]
      },
      {
        path: 'first-category',
        loadChildren: () => import('./pages/first-category/first-category.module').then(m => m.FirstCategoryModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'manage-first-category',
        loadChildren: () => import('./pages/manage-category-level-one/manage-category-level-one.module').then(m => m.ManageCategoryLevelOneModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'second-category',
        loadChildren: () => import('./pages/second-category/second-category.module').then(m => m.SecondCategoryModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'manage-second-category',
        loadChildren: () => import('./pages/manage-category-level-two/manage-category-level-two.module').then(m => m.ManageCategoryLevelTwoModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'third-category',
        loadChildren: () => import('./pages/third-category/third-category.module').then(m => m.ThirdCategoryModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'manage-third-category',
        loadChildren: () => import('./pages/manage-category-level-three/manage-category-level-three.module').then(m => m.ManageCategoryLevelThreeModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'products',
        loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'manage-products',
        loadChildren: () => import('./pages/manage-products/manage-products.module').then(m => m.ManageProductsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'reviews',
        loadChildren: () => import('./pages/reviews/reviews.module').then(m => m.ReviewsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'banners',
        loadChildren: () => import('./pages/banners/banners.module').then(m => m.BannersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'manage-banners',
        loadChildren: () => import('./pages/manage-banners/manage-banners.module').then(m => m.ManageBannersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'woocommerce',
        loadChildren: () => import('./pages/woocommerce/woocommerce.module').then(m => m.WoocommerceModule),
        canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
      },
      {
        path: 'reset',
        loadChildren: () => import('./pages/reset/reset.module').then(m => m.ResetModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
