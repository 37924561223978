/*
  Authors : initappz (Rahul Jograna)
  Website : https://initappz.com/
  App Name : ionic 5 groceryee app
  Created : 10-Sep-2020
  This App Template Source code is licensed as per the
  terms found in the Website https://initappz.com/license
  Copyright and Good Faith Purchasers © 2020-present initappz.
*/
import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'Main',
    main: [
      {
        state: 'dashboard',
        name: 'Dashboard',
        type: 'link',
        icon: 'ti-home'
      },
      {
        state: 'first-category',
        name: 'First Level Category',
        type: 'link',
        icon: 'ti-layout-grid4'
      },
      {
        state: 'second-category',
        name: 'Second Level Category',
        type: 'link',
        icon: 'ti-layout-grid2'
      },
      {
        state: 'third-category',
        name: 'Third Level Category',
        type: 'link',
        icon: 'ti-layout-grid3'
      },
      {
        state: 'products',
        name: 'Products',
        type: 'link',
        icon: 'ti-envelope'
      },
      {
        state: 'reviews',
        name: 'Reviews',
        type: 'link',
        icon: 'ti-face-smile'
      }
    ],
  },
  {
    label: 'Manage',
    main: [
      {
        state: 'stats',
        name: 'Store Stats',
        type: 'link',
        icon: 'ti-stats-up'
      },
      {
        state: 'contacts',
        name: 'Support',
        type: 'link',
        icon: 'ti-comments-smiley'
      },
      {
        state: 'banners',
        name: 'Banners',
        type: 'link',
        icon: 'ti-layout-cta-btn-right '
      },
      {
        state: 'woocommerce',
        name: 'WooCommerce',
        type: 'link',
        icon: 'ti-world'
      },
      {
        state: 'settings',
        name: 'Settings',
        type: 'link',
        icon: 'ti-settings'
      }
    ]
  },

];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  /*add(menu: Menu) {
    MENUITEMS.push(menu);
  }*/
}
